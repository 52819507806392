@import "system/variables";
@import "system/mixin";

html,
body { 
    overflow-x: hidden;
    @include respond-to(xs) {
        overflow-x: hidden;
        width: 100vw;
    }
}



body {
    font-family: $ff;
    font-weight: $light;
    overflow-x: hidden;
    color: $text;
    line-height: 1.3; 
    @include vw($font-size: 21);
    @include respond-to(xs) {
        font-size: 16px;
    }
}
sup{
    font-weight: inherit;
    color: inherit;
    font-size: 60%;
}
b,
strong {
    font-family: $ff;
    font-weight: $bold;
}

img {
    max-width: 100%;
}

.visible{
    &--desktop{
        display: block;
        @include respond-to(xs){
            display: none;
        }
    }
    &--mobile{
        display: none;
        @include respond-to(xs){
            display: block;
        }
    }
}

.site-width {
    max-width: 100%;
    @include vw($width: 1670,
        $padding: 0 15);
    margin: 0 auto;
    @include respond-to(xs){
        width: 100%;
        padding: 0 35px;
    }
}

.btn{
@include vw($border: 2 solid #fff,
$font-size: 20,
$min-height: 65,
$padding: 5 10);
font-weight: $semi-bold;
color: #fff;
text-decoration: none;
display: flex;
align-items: center;;
justify-content: center;
}

.title{
    font-weight: $bold;
    @include vw($font-size: 36,
    $margin-bottom: 30);
    color: $brand;
    position: relative;
    &:before{
        content: '';
        @include vw($width: 220,
        $height: 12,
        $top: -20);
        display: block;
        position: absolute;
        // top: 0;
        left: 0;
        background:  #9D758D;


    }
    &--light{
        &:before{
            content: '';
            background:  #F2EAEF;


        }
    }
}
 