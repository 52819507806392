@import "system/variables";
@import "system/mixin";

.index {
    &--mobile {
        display: none;

        @include respond-to(xs) {
            display: block;

        }
    }

    &--desktop {
        @include respond-to(xs) {
            display: none;

        }
    }
}

.header {
    position: absolute;
    width: 100%;
    z-index: 9;

    @include respond-to(xs) {
        z-index: 9999;
    }

    &.is-fixed {
        @include respond-to(xs) {

            background: #F7F2F5;
            box-shadow: 0 0 8px 0 rgba(178, 130, 162, 0.34);

            .btn {
                color: #634B65;
                border-color: #634B65;
            }
        }
    }

    &.is-shown {
        @include respond-to(xs) {
            transform: translateY(0) !important;
            transition: all 0.4s ease;
        }
    }

    .site-width {
        display: flex;
        justify-content: space-between;
        @include vw($padding: 50 0 50);

        @include respond-to(xs) {
            padding: 19px 35px;

        }
    }

    .logo {
        position: relative;
        @include vw($margin-left: 15,
            $width: 300);

        @include respond-to(xs) {
            margin-left: 0px;
            width: 26px;
        }

        img {
            width: 100%;
        }
    }

    &__btn {
        @include vw($width: 250);

        @include respond-to(xs) {
            margin-right: -20px;

        }

        .btn {
            @include respond-to(xs) {
                font-size: 14px;
                width: auto;
                padding: 5px 25px;
            }
        }
    }
}

.hero {
    @include vw($height: 990);
    // height: 990px;
    background: url(/images/hero-bg.jpg) center center no-repeat;
    background:#926B89;
    background-size: contain;
    z-index: 3;
    position: relative;
    &:before{
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
@include vw($height: 351);
z-index: 9;
background: linear-gradient(180deg, rgba(146, 107, 137,1) 0%,rgba(146, 107, 137,0)90%)
    }
    &:after{
content: '';
position: absolute;
bottom: 0;
left: 0;
width: 100%;
@include vw($height: 351);
z-index: 9;
background: linear-gradient(180deg, rgba(146, 107, 137,0) 0%,rgba(146, 107, 137,1)90%)
    }
&__layer{
    @include vw($height: 1127);
    // @include vw($height: 990);
    position: absolute;
    // top: 0;
    // right: 0;
    overflow: hidden;
    @include vw($right: -40,
    $top: -85
    );

    overflow: hidden;
    // top: -9%;
    svg{
        // height: 112vh;
       @include vw($height: 1170);
    //    @include vw($height: 990);
        // height: 
        width: auto;
    }
    &--bottle{
        position: absolute;
        z-index: 3;
        top: 0;
        right: 0;
        width: 60%;
        height: 100%;
        // background:  green;
overflow: hidden;

        img{
            position: absolute;
@include vw($width: 470);
&.first{
    // transform: rotate(180deg) translateY(21%);
    transform: rotate(180deg)  ;
    // top: -18%;
    top: -100%;
    @include vw($left: 20);
    @include respond-to(xs){
        top: -24%;
        // top: -4%;
        width: 170px;
        left: 28px;
    }
}
&.sec{
    // transform: rotate(0deg) translateY(31%);
    // transform: rotate(0deg) translateY(31%);
    transform: rotate(0deg);
    // bottom: 0;
@include vw($left: 440);
// bottom: -27%;
top: 81%;
@include respond-to(xs){

    // top: 69%;
    top: 89%;
    width: 170px;
    left: 28px;
}
}
        }
    }
}
    .site-width {
        height: 100%;
    }

    &__inner {
        height: 100%;
        display: flex;

    }

    &__content {
        flex-basis: 41%;
        flex-grow: 0;
        flex-shrink: 0;
        @include vw($padding-top: 245);
position: relative;
z-index: 99;
        @include respond-to(xs) {
            flex-basis: 97%;
            padding-top: 13vh;
            flex-basis: 97%;
            padding-top: 13vh;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 25vh;
        

        }
    }

    &__title {
        @include vw($font-size: 90,
            $margin-bottom: 20);
        font-weight: $bold;
        line-height: 1.13;
        color: #fff;
transform: translateY(20%);
opacity: 0;
        @include respond-to(xs) {
            font-size: 34px;
            margin-top: 20.5vh;
            margin-bottom: 1.5vh;

        }
    }

    &__text {
        @include vw($margin-bottom: 65, );
        color: #fff;
        transform: translateY(20%);
opacity: 0;
    }

    &__btn {
        @include vw($width: 310);
        position: relative;

        transform: translateY(20%);
opacity: 0;
        @include respond-to(xs) {
            width: 218px;
        }

        .btn {
            background: #fff;
            color: $dark;
            text-transform: uppercase;
            @include vw($font-size: 21);

            @include respond-to(xs) {
                min-height: 65px;
                padding: 5px 10px;
                font-size: 16px;
            }
        }

        &-img {
            position: absolute;
            left: 50%;
            transform: translate(-63%, 13%);
            @include vw($width: 140,
            );
            top: 100%;
z-index: 10;
opacity: 0;
            @include respond-to(xs) {
                display: none;
            }

            img {
                width: 100%;
            }
        }
    }

}
// .hero {
//     @include vw($height: 990);
//     // height: 990px;
//     background: url(/images/hero-bg.jpg) center center no-repeat;
//     background-size: cover;
//     z-index: 3;
//     position: relative;

//     .site-width {
//         height: 100%;
//     }

//     &__inner {
//         height: 100%;
//         display: flex;

//     }

//     &__content {
//         flex-basis: 41%;
//         flex-grow: 0;
//         flex-shrink: 0;
//         @include vw($padding-top: 245);

//         @include respond-to(xs) {
//             flex-basis: 97%;

//         }
//     }

//     &__title {
//         @include vw($font-size: 90,
//             $margin-bottom: 20);
//         font-weight: $bold;
//         line-height: 1.13;
//         color: #fff;

//         @include respond-to(xs) {
//             font-size: 34px;
//             margin-top: 20.5vh;
//             margin-bottom: 1.5vh;

//         }
//     }

//     &__text {
//         @include vw($margin-bottom: 65, );
//         color: #fff;
//     }

//     &__btn {
//         @include vw($width: 310);
//         position: relative;

//         @include respond-to(xs) {
//             width: 218px;
//         }

//         .btn {
//             background: #fff;
//             color: $dark;
//             text-transform: uppercase;
//             @include vw($font-size: 21);

//             @include respond-to(xs) {
//                 min-height: 65px;
//                 padding: 5px 10px;
//                 font-size: 16px;
//             }
//         }

//         &-img {
//             position: absolute;
//             left: 50%;
//             transform: translate(-63%, 13%);
//             @include vw($width: 140,
//             );
//             top: 100%;

//             @include respond-to(xs) {
//                 display: none;
//             }

//             img {
//                 width: 100%;
//             }
//         }
//     }

// }
.shape{
    position: absolute;
    img{
        width: 100%;
    }
&__dot{
    @include vw($width: 412);
    right: 0;
}
}
.order {
    &__shape{
        
        &--1{
            @include vw($width: 370);
            left: 40%;
            top: 0;
        }
        &--2{
            @include vw($width: 370);
            right: 2%;
            top: 0;
        }
        &--3{
            @include vw($width:90);
            left: 0;
            top: 0;
        }
        &-dot{
            @include vw($top: 780);
            z-index: 0;
        }
    }
    &-wrap{
        position: relative;

    }
    &-wrap__inner {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        ;

    }

    &__content {
        @include vw($padding-top: 100);
        flex-basis: 53.5%;
        flex-grow: 0;
        flex-shrink: 0;
    }

    &__section {
        @include vw($margin-bottom: 95);

        &--top {
            @include vw($padding-top: 180);
            @include vw($margin-bottom: 0);
            flex-basis: 100%;
            flex-basis: 72%;
            .title {
                @include vw($width: 600);
            }
        }

        &:nth-child(2) {
            @include vw($margin-bottom: 25);
        }
    }

    &__text {
        @include vw($margin-bottom: 35);
    }

    &__subtitle {
        font-weight: $med;
        @include vw($margin-bottom: 20);
    }

    &__list {
        &-item {
            @include vw($font-size: 26,
                $margin-bottom: 40,
                $padding-left: 60);
            font-weight: $bold;
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0%;
                background: url(/images/checkmark.svg) center center no-repeat;
                background-size: 98%;
                @include vw($width: 30,
                    $height: 30);
            }
        }
    }
}

.important {
    @include vw($margin-top: -8);

    &__inner {
        display: flex;
        align-items: center;
    }

    &__icon {
        @include vw($flex-basis: 190,
            $margin-left: -25);
        flex-grow: 0;
        flex-shrink: 0;
        ;
        ;

        img {
            width: 100%;
            display: block;
        }
    }

    &__content {
        @include vw($font-size: 18,
            $margin-left: -20,
            $margin-top: -12,
            $line-height: 28);

        span {
            color: #A97CA1;
            font-weight: $bold;
        }
    }
}

.subscr {
    @include vw($margin-top: 65,
        $padding-bottom: 200);
    position: relative;

    &__bg {
        position: absolute;
        width: 94%;
        @include vw($left: -45,
            $top: -75);
    }

    .title {
        @include vw($margin-bottom: 20);
    }

    .order__list {
        &-item {
            font-weight: $reg;

            span {
                font-weight: $bold;
            }
        }
    }

    &__content {
        width: 85%;
    }

    &__img {
        position: absolute;
        @include vw($right: -145,
            $top: 330,
            $width: 210);
            @include respond-to(xs){
                right: auto;
    top: 0px;
    width: 30%;
    z-index: 999; 
            }
            img {  transition: .6s transform ease;
                display: block;
                width: 100%;
            }
            &--2{
                position: absolute;  width: 100%;
                top: 0;
                left:0;
                opacity: 0;
                transform: rotateY(90deg);
                
              }
             .subscr__img--3{
                // width: 80px;
                display: block;
                position: absolute;
                top: 0px;
                @include vw($width: 140);
                transform: translate(90%, -80%);
                transition: .6s all ease;
              }
              .subscr__img--4{
                  display: block;
                @include vw($width: 140);
                position: absolute;
                top: 0px;
                transform: translate(90%, -80%);
                transition: .6s all ease;
                opacity: 0;
               
              }
            &.is-active{
                .subscr__img--1{
                    transform: rotateY(90deg);
opacity: 0;
                }
                .subscr__img--2{
transform: rotateY(0deg);
opacity: 1;
@include respond-to(xs){
    z-index: 999;
    transform: translateY(-25%);
    opacity: 1;
}
                }
                .subscr__img--3{ 
opacity: 0;
                }
                .subscr__img--4{ 
opacity: 1;
@include respond-to(xs){
    transform: translate(80%, -150%);
width: 76%;
opacity: 1;
}
                }

            }
      
    }
}

.order {
    @include vw($flex-basis: 525,
        $margin-right: 70);
    flex-grow: 0;
    flex-shrink: 0;

    &__inner {
        @include vw($border-radius: 32,
            $padding: 0 60 25);
        box-sizing: border-box;
        background: linear-gradient(180deg, rgba(242, 234, 239, 0) 0%, rgba(242, 234, 239, 0.13) 15%, rgba(242, 234, 239, 0.78) 27%, rgba(242, 234, 239, 1) 100%);
        ;

        display: flex;
        flex-direction: column;

    }

    &__imgs {
        // @include vw($height: 532);
        width: 100%;
        position: relative;
    }

    &__img {
    @include respond-to(xs){
        position: relative;
    }
    svg{
        @include respond-to(xs){
            position: absolute;
    left: 0;
    top: 0;
    width: 100%;
        }
    }
        &-bg {
            width: 100%;
            bottom: 0;
            height: auto;
            // position: absolute;

        }
&-lamp{
    // color: #f1ead8;
    position: absolute;
    width: 50%;
    left: 50%;
    height: auto;
    // top: -50%;
    // transform: translate(-50%, -200%);
    top: -180%;
    transform: translate(-40%,-60%);
    // @include vw($top: -490);
}
        &-bottle {
            position: absolute;
            @include vw($width: 16,
                $height: 30,
                $bottom: 98,
                $left: 226);

            img {
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
            }
        }
    }

    &-table {
        &__header {
            @include vw($font-size: 32,
                $border-radius: 8,
                $height: 65);
            display: flex;
            justify-content: center;
            align-items: center;
            ;
            font-weight: $bold;
            color: #fff;
            text-align: center;
            background: $dark;
        }

        &__content {
            @include vw($font-size: 32,
                $border-radius: 8,
                // $border: 4 solid #fff,

                $margin-top: 1,
                $padding: 23 20,
            );
            text-decoration: none;
            display: block;
            transition: all 0.6s ease;
            background: #fff;
        }

        &__block {
            @include vw($margin-bottom: 22);
            z-index: 9;
            position: relative;
            &--subscr {
                display: none;

                .order-table__checkbox {
                    background: $dark;

                    img {
                        display: block;
                    }
                }
            }

            &:nth-child(1) {
                .order-table__content {
                    // @include vw($box-shadow: 0 0 0 5 $dark inset, );
                }
            }

            &:nth-child(2) {
                .order-table__content {
                    // @include vw($box-shadow: 0 0 0 2 $dark inset, );
                }
            }

            &:hover {
                .order-table__content {
                    @include vw($box-shadow: 0 0 0 5 $dark inset, );  

                }
                .order-table__save{
                    transform: scale(1.017);
                }
            }
        }

        &__info {
            display: flex;
        }

        &__checkbox {
            @include vw($width: 60,
                $height: 60,
                $border: 4 solid #B282A2,
                $border-radius: 8,
                $margin-right: 35);
            background: #fff;
            flex-grow: 0;
            flex-shrink: 0;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            ;

            img {
                // position: absolute;
                display: block;
                width: 60%;
                display: none;
            }

            &.is-active {
                background: $dark;

                img {
                    display: block;

                }
            }
        }

        &__price {
            @include vw($font-size: 26,
                $padding-top: 10);
            font-weight: $bold;

            &-value {
                @include vw($font-size: 32);
            }

            &--regular {
                font-weight: $reg;
            }
        }

        &__save {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            ;
            @include vw($height: 65,
                $margin: 10 auto 17);
            font-weight: $bold;
            color: #926B89;
            background: #F2EAEF;
            transition: all 0.6s ease;
        }

        &__footer {
            background: #B282A2;
            position: relative;

            @include vw($font-size: 32,
                $border-radius: 8,
                // $border: 4 solid #fff,

                $margin-top: 1,
                $padding: 23 20 45,
            );
            text-decoration: none;
            display: block;

            &>a {
                text-decoration: none;
            }

            &-title {
                @include vw($font-size: 32,
                    $margin-top: 30,
                    $line-height: 42,
                    $margin-bottom: 30);
                color: #fff;
                font-weight: $bold;
                text-align: center;
                text-decoration: none;

            }

            &-img {
                position: absolute;
                @include vw($width: 156,
                    $bottom: -50,
                    $right: -120);

                img {
                    width: 100%;
                }
            }

            .order-table__checkbox {
                background: none;
                @include vw($border: 4 solid #fff,
                    $margin-left: 10,
                    $margin-right: 25);
            }

            .order-table__price {
                @include vw($padding-top: 0);
                font-weight: $reg;
                @include vw($font-size: 21);
            }
        }

        &__faq {
            text-align: center;
            @include vw($padding: 15 60);
        }
    }
    &-subscription{ 
        // display: block;
&__img{
    position: relative;
    // background:  url('/images/shapes.png') center center no-repeat;
    background-size: contain;
    @include vw($margin-bottom: 10);

    &-bg{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        top: 10%;
    }
    img{
        display: block;
        width: 60%;
        margin: auto;
    }
    
    
}
    &__descr{
        @include vw($font-size: 21,
        $margin-bottom: 40);
    }
    &__regular{ 
        font-weight: $med;
        @include vw($font-size: 24,
        $margin-bottom: 20
         );
         text-align: center;
         span{
             display: block;
            font-weight: $med;
            @include vw($font-size:36,
             ); 
         }
     
    }
    &__price{
        font-weight: $bold;
        @include vw($font-size: 36,
        $margin-bottom: 40
         );
         text-align: center;
         color: $dark;
         text-transform: uppercase;;
         span{
             display: block;
            font-weight: $med;
            color: #000;
            font-weight: $bold;

            @include vw($font-size:56,
            $margin-top: 10
             );
             small{
                font-weight: $bold;
                 @include vw($font-size: 14);
             }
         }
    }
}
}

.faq {
    @include vw($margin-top: 155);
    // overflow-x: hidden;
    position: relative;
&__shape{
    &--1{
     left: -30%;
     top: -15%;
     @include vw($width: 800,);
z-index: -1;
    }
}
    &__section {
        // width: 47%;
        @include vw($margin-bottom: 75);

        &:last-child {
            @include vw($margin-bottom: 0);
        }
    }

    &__title {
        color: #644B66;
        @include vw($font-size: 26,
            $margin-bottom: 15);
        font-weight: $bold;
    }

    &__text {}
}

.cards {
    @include vw($margin-top: 70);

    &__inner {
        display: flex;
        justify-content: center;

    }

    &__title {
        text-align: center;
        @include vw($font-size: 38,
            $margin: 30 auto 110);
        color: #9D758D;
        font-weight: $bold;
    }

    &__btn {
        @include vw($width: 536,
            $height: 124,
            $margin: 20 auto 0);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        @include vw($border: 4 dashed #644B66);
        text-decoration: none;
        position: relative;
        background: url(/images/cards-btn-shape.svg) no-repeat;

        @include vw($background-size: 110,
            $background-position: 60);

        a {
            display: block;
            text-decoration: none;
            font-weight: $bold;
            @include vw($font-size: 26);
            color: #634B65;
        }

        &-man {
            position: absolute;
            right: 0;
            display: block;
            @include vw($right: -30,
                $width: 65);
            transform: translateX(100%);
        }

        &-arrow {
            position: absolute;
            right: 0;
            display: block;
            @include vw($left: -15,
                $top: -30,
                $width: 41);
            transform: translateX(-100%);
        }
    }
}

.card {
    @include vw($flex-basis: 400,
        $margin: 0 40 20,
        $border-radius: 8);
    background: #B282A2;
    position: relative;
    @include vw(
        // $border: 4 solid #B282A2,
        $padding: 20 35 27);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-shadow: 0 0 18px 0 rgba(178, 130, 162, 0.24);
    color: rgb(178, 130, 162);
position: relative;
overflow: hidden;
&__shape{
    z-index: -1; 
    img{
            opacity: 0.25;
        }
    &--1{
        bottom: -0%;
        right: -5%;
        @include vw($width: 85,
        $height: 80);
        img{
            opacity: 0.25;
        }
    }
    &--2{
        top: 20%;
        left: -10%;
        
        @include vw($width: 85,
        $height: 80);
        img{
            opacity: 0.25;
        }
    }
    &--3{
        top: 6%;
        right: 0%;
        
        @include vw($width: 285,
        $height: 180);
        img{
            opacity: 0.25;
        }
    }
    &--4{
        bottom: 50%;
        left: -2%;
        
        @include vw($width: 60,
        $height: 50);
        img{
            opacity: 0.25;
        }
    }
}
    &__img {
        @include vw($width: 120,
            $margin: 25 auto 40);
            position: relative;
           
    }

    &__title {
        color: #fff;
        @include vw($font-size: 26,
            $margin-bottom: 20);
        font-weight: $bold;
        position: relative;
    }

    &__link {
        color: #644B66;
        text-decoration: none;
        @include vw($font-size: 21);
position: relative;
        &:hover {
            color: #fff;
        }
    }

    &:nth-child(2) {
        .card__img {
            @include vw($width: 155,
                $margin: 45 auto 20);
        }
    }

    &.card--bottom {
        @include vw($padding-bottom: 20,
            $margin-bottom: 30);

        &:nth-child(1) {
            .card__img {
                @include vw($width: 120,
                    $margin: 25 auto 20);
            }
        }

        &:nth-child(2) {
            .card__img {
                @include vw($width: 155,
                    $margin: 0 auto 50);
            }
        }
    }

}

.feedback {
    @include vw($margin-top: 90);
position: relative;
&__shape{
    &--1{
        z-index: 0;
        @include vw($width: 250);
    }
    &--2{
        @include vw($width: 900);
        right: -16%;
        top: -16%;
        z-index: -1;

    }
    &--3{
        @include vw($width: 400);
        right: -1%;
        top: 36%;
        z-index: -1;
    }
}
    &__header {
        position: relative;
        @include vw($margin-bottom: 160);

        &-text {}
    }

    &-rating {
        position: relative;
        &__wrap {
            display: flex;

            // align-items: center;;
            @include respond-to(xs) {
                flex-wrap: wrap;
            }
        }

        &__content {
            flex-basis: 31%;
            flex-grow: 0;
            flex-shrink: 0;

            @include respond-to(xs) {
                flex-basis: 100%;
            }

            .title {
                @include vw($margin-bottom: 65);

                @include respond-to(xs) {
                    display: none;
                }

            }
        }

        &__text {
            @include vw($margin-bottom: 40);
        }

        &__list {
            @include respond-to(xs) {
                padding-left: 0 !important;
            }
        }

        &__item {
            display: flex;
            @include vw($margin-bottom: 13);
        }

        &__title {
            color: #644B66;
            font-weight: $bold;
            @include vw($font-size: 26,
                $margin-right: 20);

            @include respond-to(xs) {
                font-size: 16px;
            }
        }

        &__star {
            @include vw($padding: 0 9);

            svg {
                @include vw($width: 32,
                    $height: 32,
                    $margin-right: 8);

                @include respond-to(xs) {
                    width: 20px;
                    height: 20px; 
                }

                // &:hover {
                //     cursor: pointer;
                //     fill: #B282A2;
                // }
            }
        }
    }

    &__share {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 38%;
        text-align: center;
        @include vw($padding: 0 20,
            $margin-top: 245);

        @include respond-to(xs) {
            margin-top: 20px;
            flex-basis: 100%;
        }

        &-title {
            @include vw($font-size: 26,
                $margin-bottom: 30);
            font-weight: $bold;
            color: #634B65;
            text-align: center;
            display: block;

            @include respond-to(xs) {
                font-size: 16px;
            }

        }

        &-btn {
            @include vw($font-size: 21,
                $width: 328,
                $height: 65,
                $border-radius:2,
                $margin: 0 auto);
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: $semi-bold;
            color: #fff;
            background: #B282A2;
            text-decoration: none;
            transition: all 0.6s ease;

            @include respond-to(xs) {
                width: 276px;
                height: 49px;
                font-size: 18px;
                background: #916A88;
                color: #fff;
                border-radius: 8px;
                ;
                text-decoration: none;
                box-shadow: 0 0 18px 0 rgba(178, 130, 162, 0.24);
                margin: 24px auto 8px;
            }

            &:hover,
            &:focus {
                color: $dark;
                background: rgba(242, 234, 239, 1);
            }
        }
    }

    &__item {
        position: relative;
        @include vw($margin-bottom: 50);

        @include respond-to(xs) {
            margin-bottom: 30px;
        }

        &-header {
            display: flex;
            align-items: center;
            @include vw($margin-bottom: 30);
        }

        &-name {
            color: #634B65;
            display: flex;
            align-items: center;
            justify-content: center;
            @include vw($width: 398,
                $padding: 5 15,
                $height: 72,
                $border: 4 solid #644B66,
                $border-radius: 8,
                $font-size: 26,
                $margin-right: 15);
            font-weight: $bold;

            @include respond-to(xs) {
                font-size: 14px;
                width: auto;
                height: auto;
                min-width: 165px;
                padding: 7px 10px;
            }

            ;
        }

        &-star {
            svg {
                @include vw($width: 32,
                    $height: 32,
                    $margin-right: 8);

                @include respond-to(xs) {
                    width: 15px;
                    height: 15px;
                }
            }
        }

        &-content {
            background: #F2EAEF;
            box-shadow: 0 0 20px 0 rgba(178, 130, 162, 0.24);
            @include vw($padding: 67 140,
                $min-height: 348);

            @include respond-to(xs) {
                padding: 15px 30px;
            }

            p {
                font-weight: $med;
                font-style: italic;
                ;
                @include vw($line-height: 31.3);

                @include respond-to(xs) {
                    line-height: 1.4;
                }
            }
        }
    }

    &__btn {
        @include vw($font-size: 21,
            $width: 328,
            $height: 65,
            $border-radius:2,
            $margin: 0 auto);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: $semi-bold;
        color: #fff;
        background: #B282A2;
        text-decoration: none;
        transition: all 0.6s ease;

        @include respond-to(xs) {
            width: 276px;
            height: 49px;
            font-size: 18px;
            background: #916A88;
            color: #fff;
            border-radius: 8px;
            ;
            text-decoration: none;
            box-shadow: 0 0 18px 0 rgba(178, 130, 162, 0.24);
            margin: 24px auto 8px;
            border: none;
        }

        &:hover,
        &:focus {
            color: $dark;
            background: rgba(242, 234, 239, 1);
        }
    }

    .j-package_preloader {
        @include vw(margin, 20 auto);
    }

    &__form {
        text-align: center;
        @include vw($padding-top: 90,
            $width: 700,
            $margin: 0 auto);

        .title {
            color: #644B66;
            @include vw($font-size: 32,
                $margin-bottom: 15);
            font-weight: $bold;

            @include respond-to(xs) {
                font-size: 24px;
                margin-bottom: 20px;
            }

            &:before {
                display: none;
            }

        }

        &-rating {
            display: flex;
            align-items: center;
            justify-content: center;
            @include vw($margin-bottom: 40);

            @include respond-to(xs) {
                flex-wrap: wrap;
            }

            svg {
                @include vw($width: 32,
                    $height: 32,
                    $margin-right: 8);

                @include respond-to(xs) {
                    width: 15px;
                    height: 15px;
                }
            }
        }

        &-overall {
            font-weight: $med;

            @include respond-to(xs) {
                width: 100%;
                margin-bottom: 7px;
            }

            // @include vw($margin-bottom: 20);
        }
    }

    .control-label,
    .help-block {
        display: none !important;
    }

    .js-reviews-form_view {
        display: none;
    }
}

.form-group {
    @include vw($margin-bottom: 20);
}

.form-control {
    @include vw($min-height: 101,

        $border: 2 solid #644B66,
        $border-radius: 0,
        $padding: 20 30,
        $margin-bottom: 35);
    @include vw($font-size: 32);
    color: $dark;

    @include placeholder {
        color: #B282A2;
    }
}

textarea.form-control {
    @include vw($min-height: 301);
}

.complex {
    @include vw($margin-top: 10);

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__bottle {
        flex-basis: 42.3%;
        flex-grow: 0;
        flex-shrink: 0;
        text-align: center;
        @include vw($padding-top: 110);

        img {
            width: 119%;
            margin-left: -7%;
            max-width: none;
            display: block;
        }
    }

    &__cards {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        @include vw($padding-top: 40);
    }

    &__card {
        flex-basis: 49%;
        flex-grow: 0;
        flex-shrink: 0;
        @include vw($border-radius: 8,
            $padding: 30 30 35);
        text-align: center;
        box-shadow: 0 0 24px 0 rgba(178, 130, 162, 0.44);

        &-header {
            @include vw($font-size: 60);
            font-weight: $reg;
            @include vw($border-bottom: 4 solid #654C67,
                $margin-bottom: 30);

            span {
                display: block;
                font-weight: $bold;
                color: #fff;
                @include vw($margin-top: -5);
            }
        }

        &-btn {
            .btn {
                text-transform: uppercase;
                color: #644B66;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: $semi-bold;
                background: #fff;
                text-decoration: none;
                transition: all 0.6s ease;
                @include vw($width: 270,
                    $height: 57,
                    $margin: 0 auto);

                &:hover,
                &:focus {
                    color: $dark;
                    background: rgba(242, 234, 239, 1);
                }
            }

        }
    }


    &__deal {
        background: #B282A2;
        color: #644B66;

        p:nth-child(2) {
            color: #fff;
            @include vw($font-size: 26);
            font-weight: $bold;
        }

        p:nth-child(3) {
            font-weight: $bold;
            color: #fff;
            @include vw($font-size: 45,
                $margin-bottom: -7);
        }

        p:nth-child(4) {
            font-weight: $bold;
            color: #000;
            @include vw($font-size: 26);
        }

        p:nth-child(5) {
            font-weight: $light;
            color: #000;
            text-decoration: line-through;
            @include vw($font-size: 21,
                $margin-bottom: 0);

        }

        p:nth-child(6) {
            font-weight: $bold;
            color: #000;
            @include vw($font-size: 45,
                $margin-bottom: 5);
        }

        p:nth-child(7) {
            font-weight: $bold;
            color: #fff;
            @include vw($font-size: 26,
                $margin-bottom: 10);
        }

        p:nth-child(8) {
            font-weight: $bold;
            color: #fff;
            line-height: 1;
            @include vw($font-size: 45,
                $margin-bottom: 0);
        }

        p:nth-child(9) {
            font-weight: $bold;
            color: #000;
            line-height: 1;
            @include vw($font-size: 26,
                $margin-bottom: 6);
        }

        p:nth-child(10) {
            font-weight: $light;
            color: #000;
            text-decoration: line-through;
            @include vw($font-size: 21,
                $margin-bottom: 55);
        }

        p:nth-child(11) {
            font-weight: $bold;
            color: #fff;
            @include vw($font-size: 26,
                $margin-bottom: 10);
        }

        p:nth-child(12) {
            font-weight: $bold;
            color: #000;
            @include vw($font-size: 69,
                $margin-bottom: 25);
        }


    }

    &__subscr {
        background: #644B66;
        // color: #644B66;

        header {
            color: #B282A2;
            @include vw($border-bottom: 4 solid #B282A2);
        }

        p:nth-child(2) {
            color: #B282A2;
            @include vw($font-size: 26,
                $margin: 0 0 15);
            font-weight: $bold;
        }

        p:nth-child(3) {
            font-weight: $bold;
            color: #fff;
            line-height: 1;
            @include vw($font-size: 80,
                $margin-bottom: 15);
        }

        p:nth-child(4) {
            font-weight: $bold;
            color: #fff;
            line-height: 1;
            @include vw($font-size: 26,
                $margin-bottom: 3);
        }

        p:nth-child(5) {
            font-weight: $light;
            color: #fff;
            text-decoration: line-through;
            @include vw($font-size: 21,
                $margin-bottom: 18);

        }

        p:nth-child(6) {
            font-weight: $bold;
            color: #fff;
            @include vw($font-size: 26);
        }

        p:nth-child(7) {
            font-weight: $light;
            color: #fff;
            text-decoration: line-through;
            @include vw($font-size: 21,
                $margin-bottom: 0);
        }

        p:nth-child(8) {

            font-weight: $bold;
            color: #fff;
            line-height: 1;
            @include vw($font-size: 26,
                $margin-bottom: 15,
                $margin-top: 135);
        }


        p:nth-child(9) {
            font-weight: $bold;
            color: #B282A2;
            @include vw($font-size: 69,
                $margin-bottom: 25);
        }


    }


}

.matter {
    @include vw($margin-top: 15);

    &__title {
        @include vw($font-size: 36,
            $margin-bottom: 30);
        font-weight: $bold;
        color: #9D758D;
    }

    p {
        @include vw($margin-bottom: 30);
    }
}

.contact {
    @include vw($margin-top: 95);

    .title {
        @include vw($margin-bottom: 20);
    }

    &__inner {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        ;
    }

    &__img {
        flex-basis: 52%;
        flex-grow: 0;
        flex-shrink: 0;

        img {
            display: block;
            @include vw($margin: 63 auto 0);
            width: 72%;
        }
    }

    &__form {
        @include vw($flex-basis: 636,
            // $margin-left: 115,
            $margin-top: 65);
        flex-grow: 0;
        flex-shrink: 0;
p{
    @include vw($margin-bottom: 20);
}
h3{
    @include vw($font-size: 24,
    $margin-bottom: 20);
    font-weight: $med;
}
a{
    display: block;
    // @include vw($font-size: 22);
    font-weight: $bold;
}
        textarea {
            @include vw($padding: 35 30);

            @include placeholder {
                color: #B282A2;
                @include vw($font-size: 21);
            }
        }
    }

    &__btns {
        display: flex;
        justify-content: space-between;

        .btn,
        .livechat_button {}

        .livechat_button {
            display: flex;
            text-transform: uppercase;
            align-items: center;
            justify-content: center;
            flex-basis: 48%;
            flex-grow: 0;
            flex-shrink: 0;
            transition: all 0.6s ease;
            background: #B282A2;
            @include vw($height: 65,
                $border-radius:2,
            );

            a {
                @include vw($font-size: 21);

                font-weight: $semi-bold;
                color: #fff;
                text-decoration: none;
                transition: all 0.6s ease;


            }

            &:hover,
            &:focus {

                background: rgba(242, 234, 239, 1);

                a {
                    color: $dark;
                }
            }


        }

        .btn {
            display: flex;
            text-transform: uppercase;
            align-items: center;
            justify-content: center;
            flex-basis: 48%;
            flex-grow: 0;
            flex-shrink: 0;
            transition: all 0.6s ease;
            background: #fff;
            @include vw($height: 65,
                $border-radius:2,
            );

            @include vw($font-size: 21,
                $border: 2 solid #B282A2);

            font-weight: $semi-bold;
            color: #B282A2;
            ;
            text-decoration: none;
            transition: all 0.6s ease;


            &:hover,
            &:focus {

                color: $dark;
                background: rgba(242, 234, 239, 1);
                @include vw($border: 2 solid rgba(242, 234, 239, 1));

            }

        }
    }

}

.references {
    @include vw($margin-top: 60);
    // overflow: hidden;
    position: relative;
    @include vw($padding-bottom: 60);
.shape{
    &--1{
        position: absolute;
        bottom: 0;
        @include vw($width: 300);
    }
    &--2{
        position: absolute;
        bottom: 0;
        right: 0;
        @include vw($width: 200);
    }
}
    &__inner {
        background: #F2EAEF;
        @include vw($padding: 30);
        box-shadow: 0 0 24px 0 rgba(178, 130, 162, 0.24);
        width: 83%;
        margin: 0 auto;
        position: relative;
    }

    &__title {
        @include vw($font-size: 45,
            $margin-bottom: 20);
        color: #B282A2;
        font-weight: $bold;
    }

    &__list {
        @include vw($padding: 0 20);

        li {
            list-style: decimal;
            ;
            @include vw($font-size: 18,
                $margin-bottom: 15);
            font-weight: $light;
        }
    }
}

.footer {
 
background:  #fff;
position: relative;
    &__top {
        @include vw($padding: 40 0);
        background: #B282A2;

        .site-width {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include respond-to(xs) {
                flex-wrap: wrap;
                justify-content: center;
            }

        }

        &-title {
            @include vw($font-size: 26,
                $padding-left: 50);
            font-weight: $bold;
            color: #fff;

            @include respond-to(xs) {
                font-size: 14px;
                margin-bottom: 15px;
            }
        }

        &-btn {
            .btn {
                @include vw($width: 260);
                background: #fff;
                color: #B282A2;

                @include respond-to(xs) {
                    font-size: 14px;
                    width: auto;
                    height: 45px;
                    padding: 0 20px;
                }

                &:hover {
                    color: $dark;
                    background: rgba(242, 234, 239, 1);
                    // @include vw($border: 2 solid rgba(242, 234, 239, 1));
                }
            }
        }
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        @include vw($padding-top: 110,
            $margin-bottom: 140);

        @include respond-to(xs) {
            flex-wrap: wrap;
            justify-content: center;
        }

    }

    &__col {
        flex-basis: 33%;
        display: flex;
        align-items: center;
        @include vw($padding-left: 60,
            $padding-bottom: 40);
        ;
        position: relative;

        @include respond-to(xs) {
            flex-basis: 100%;
            // text-align: center;
            margin-bottom: 30px;
            padding-left: 0;
        }

        &:after {
            content: '';
            position: absolute;
            @include vw($width: 342,
                $height: 2,
                $bottom: 0,
                $left: 60);
            background: #644B66;

            @include respond-to(xs) {
                width: 90%;
                height: 1px;
            }
        }
    }

    &__icon {
        @include vw($width: 94,
            $margin-right: 10);

        @include respond-to(xs) {
            width: 60px;
            margin-right: 30px;
        }

        img {
            width: 100%;
        }
    }

    &__title {
        @include vw($font-size: 18);
        font-weight: $bold;
        color: #644B66;
        text-decoration: none;

        @include respond-to(xs) {
            font-size: 14px;
        }
    }

    &__subtitle {
        @include vw($font-size: 15);
        font-weight: $reg;
        color: #B282A2;

        @include respond-to(xs) {
            font-size: 12px;
        }
    }

    &__inform {
        a {
            display: block;
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        ;
        @include vw($font-size: 18,
            $margin-bottom: 60);
        color: #B282A2;
        font-weight: $reg;

        @include respond-to(xs) {
            font-size: 14px;
            flex-wrap: wrap;
            justify-content: center;
        }

        &__copyright {
            @include respond-to(xs) {
                margin-bottom: 10px;
            }
        }

        a {
            color: #B282A2;
            text-decoration: none;
            line-height: 1;
            @include vw($padding-right: 10,
                $border-right: 1 solid #B282A2,
                $margin-right: 10);

            &:last-child {
                @include vw($border-right: 0);
            }

            &:hover {
                color: $dark;
            }
        }
    }
}

.index--mobile {
    .hero {
        height: 100vh;
        // background: url(/images/m-hero-bg.jpg) center center no-repeat;
        background:#926B89;
        background-size: cover;
        &__layer{
            height: 100%;
            right: 0;
            top: 0;
             
        }
    }

    .order {

        &-wrap {
            padding-top: 76px;
            min-height: 100vh;
            ;
        }

        &__title {
            font-size: 21px;
            font-weight: $bold;
            color: #916B88;
            text-align: center;
            margin-top: 25px;
            margin-bottom: 10px;
        }

        &__subtitle {
            font-size: 14px;
            color: #634B65;
            text-align: center;
        }

        &__form {}

        &__img {
            width: calc(100% + 70px);
            margin-left: -35px;
            margin-right: -35px;
            text-align: center;

            img {
                width: 90%;
            }
        }

        &__row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            ;
            color: #634B65;
            font-size: 14px;
            font-weight: $reg;
            margin-bottom: 10px;
            padding: 0 13px;
        }

        &__saving {
            margin-bottom: 0px;

            span {

                color: #EA4242;
                font-size: 14px;
                font-weight: $bold;
                margin-bottom: 10px;
            }
        }

        &__shipping {
            margin-bottom: 15px;
        }

        &__total {
            background: #F2EAEF;
            padding: 16px 13px;
            margin-bottom: 5px;

            .order__row {
                margin-bottom: 0;
            }

            span {
                &:nth-child(2) {
                    span {

                        font-weight: $bold;
                    }
                }
            }
        }

        &__placeholder {
            color: #634B65;
            font-weight: $med;
            font-size: 12px;
            text-align: center;
            margin-bottom: 18px;
        }

        &__select {
            &-name {
                color: #634B65;
                display: block;
                font-size: 14px;
                box-sizing: border-box;
                cursor: pointer;
                position: relative;
                background: #F2EAEF;
                padding: 16px 13px;
                margin-bottom: 5px;

                &:after {
                    content: '';
                    top: 24px;
                    right: 16px;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 9px solid transparent;
                    border-right: 9px solid transparent;
                    border-top: 9px solid #906A87;
                    margin-top: -2px;
                }

                // &.rotate{
                //     &:after{
                //         border-left: 9px solid transparent;
                //         border-right: 9px solid transparent;
                //         border-bottom: 9px solid #906A87;
                //        border-top: 0;
                //     }
                // }

            }

            &-list {
                display: none;

                &.is-active {
                    display: block;
                }
            }

            &-list-item {

                // margin-top: 1px; 
                width: 100%;
                color: #634B65;
                display: block;
                font-size: 14px;
                box-sizing: border-box;
                cursor: pointer;
                position: relative;
                background: #F2EAEF;
                padding: 16px 13px;
                margin-bottom: 1px;

            }
        }

        &__btn {
            width: 276px;
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            background: #916A88;
            color: #fff;
            border-radius: 8px;
            ;
            text-decoration: none;
            box-shadow: 0 0 18px 0 rgba(178, 130, 162, 0.24);
            margin: 24px auto 8px;
        }

        &__guarantee {
            color: #634B65;
            font-size: 12px;
            text-align: center;

            span {
                display: block;
                font-weight: $med;
            }
        }

        //         &__qty{
        //         }
    }

    .accordion {
        &__header {
            color: #634B65;
            font-size: 18px;
            padding-left: 40px;
            font-weight: $bold;
            position: relative;
            padding: 17px 0 17px 40px;
            cursor: pointer;

            span {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                border: 4px solid #B282A2;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                display: block;
display: flex;
justify-content: center;
align-items: center;
                &:before {
                    content: '+';
                    color: #B282A2;
                    font-size: 20px;
                    font-weight: $bold;
                    line-height: 1;
                    // position: absolute;
                    // top: 0px;
                    // left: 50%;
                    // transform: translateX(-50%);
                    display: block;

                }

            }
        }

        &__content {
            width: 90%;
            margin: auto;
            padding-bottom: 40px;
            padding-top: 0px;
            display: none;
            line-height: 1.4;

            .bold {
                font-weight: $bold;
                margin-bottom: 10px;
                line-height: 1.4;
            }

            .brand {
                color: $dark;
            }

            .center {
                text-align: center;
            }

            p {
                margin-bottom: 15px;
            }

            ul {
                list-style: disc;
                ;
                padding-left: 20px;

                li {
                    line-height: 1.4;
                    padding-bottom: 10px;
                }
            }
        }
    }

    .mb-4 {
        margin-bottom: 20px;
    }
    .js-hide-row{
        display: none;
    }
}


.supplements {
    border: 2px solid #3b3838;
    min-width: 310px;
    width: 100%;

    tbody tr {
        border-top: 1px solid;
    }

    tbody tr.supplements_header th {
        font-size: 24px;
    }

    tbody tr.supplements_header td {
        border-bottom: 0px solid #3b3838;
        border-top: 4px solid #3b3838;
    }

    tbody tr.supplements_note {
        font-size: 12px;
    }

    tbody tr.supplements_note td {
        border-bottom: medium none;
        border-top: medium none;
    }

    tbody tr.supplements_note td:first-child {
        font-size: 12px;
        padding-left: 20px;
        padding-top: 0;
    }

    tbody tr td {
        /*text-align: center;*/
        padding: 5px 20px;
    }

    tbody tr:first-child td:first-child {
        text-align: right;
        padding: 5px 6px;
    }

    tbody tr td.r-padding {
        padding-left: 15px;
    }

    tfoot tr td {
        border-top: 1px solid #3b3838;
    }

    tfoot tr td {
        border-top: 4px solid #3b3838;
    }

    tfoot td {
        padding-bottom: 5px;
        padding-top: 5px;
    }

    th {
        font-size: 16px;
        padding: 3px 5px;
        line-height: 1.3;
    }

    td {
        font-size: 16px;
        padding: 3px 5px;
        line-height: 1.3;
    }

    .supplements__title th {
        font-size: 24px;
    }

    .supplements__title-row th {
        font-weight: normal;
        padding-top: 0;
    }
}


/*
=================================================================
	supplement
=================================================================
*/
.b-modal_suppl {
	.modal-body{
		@include vw($padding: 25);
		display: flex;
		background: url(/images/modal_bg.png) no-repeat bottom right;
		@include vw($background-size: 600);  
		justify-content: space-between;
		align-items: flex-end;

		h3{
			@include vw($font-size: 45);
		}
		p{
            @include vw($font-size: 24
            );
		}
		&-left{
			@include vw($flex-basis: 550);
		}
		.b-vegan{
			color: $dark;
			font-weight: bold;
		}
	}
}
.l-product_supplements { 
    width: 100%;
    @include vw($border: 3 solid #3b3838,
    $min-width: 310);
}
.l-product_supplements tbody tr { 
    @include vw($border-top: 1 solid);
}
.l-product_supplements tbody tr.l-product_supplements_header th {
    @include vw($font-size: 26);  
}
.l-product_supplements tbody tr.l-product_supplements_header td { 
    @include vw($border-top: 4 solid #3b3838,
    $border-bottom: 0 solid #3b3838);
}
.l-product_supplements tbody tr.l-product_supplements_note {
	font-size: 12px;
	@include vw($font-size: 18);
}
.l-product_supplements tbody tr.l-product_supplements_note td {
	border-bottom: medium none;
	border-top: medium none;
}
.l-product_supplements tbody tr.l-product_supplements_note td:first-child {
	font-size: 12px;
	@include vw($font-size: 18);
	padding-left: 20px;
	@include vw($padding-left: 20);
	padding-top: 0;
}
.l-product_supplements tbody tr td {
	/*text-align: center;*/
	padding: 5px 20px;
	@include vw($padding: 5 10);
}
.l-product_supplements tbody tr:first-child  td:first-child {
	text-align: right;
	padding: 5px 6px;
	@include vw($padding: 5 6);
}
.l-product_supplements tbody tr td.r-padding {
	padding-left: 15px;
	@include vw($padding-left: 10);
}
.l-product_supplements tfoot tr td {
    border-top: 1px solid #3b3838;
    @include vw($border-top: 2 solid #3b3838);
}
.l-product_supplements tfoot tr td {
    @include vw($border-top: 4 solid #3b3838);
	 
}
.l-product_supplements tfoot td {
	padding-bottom: 5px;
    padding-top: 5px;
    @include vw($padding-bottom: 5,
    $padding-top: 5);
}
.l-product_supplements th {
	font-size: 14px;
	@include vw($font-size: 18);
	@include vw($padding: 5 7);
	line-height: 1.3;
	/*padding: 3px 5px;*/
}
.l-product_supplements td {
	font-size: 14px;
	@include vw($font-size: 18);
	@include vw($padding: 5 7);
	line-height: 1.3;
}
.l-product_supplements .l-product_supplements_title th {
	font-size: 23px;
	@include vw($font-size: 26);
}
.l-product_supplements .l-product_supplements_title_row th {
	font-weight: normal;
	padding-top: 0;
}

.b-modal{
	.modal-dialog{ 
        @include vw($width: 1200,
        $margin-top: 100);
	}
	.modal-header{
		padding: 0;
		height: 0;
		.close{ 
            @include vw($margin: 30 30 0 0 );
			color: $dark;
			opacity: 0.6;
			position: relative;
			z-index: 999;
			span{
				color: $dark; 
                @include vw($font-size: 45);

			}
		}
	}
	.modal-body{ 
        @include vw($padding: 50 80);
		h3{ 
            @include vw($font-size: 45,
            $padding: 30); 
			color: #926b89;
    background: #f2eaef;
			font-weight: bold;
			line-height: 1;
            text-align: center;
            @include vw($max-width: 1200,
            $margin: 30 auto 50); 

		}
		h4{
			color: $dark;
			font-weight: bold;
			
		}
		p{ 
            @include vw($font-size: 24,
            $margin-bottom: 20);
		}
		a{
			text-decoration: underline;
			color: $brand;
			&:hover{
				text-decoration: none;
			}
		}
		.b-modal_text{
			@include vw($margin-bottom: 70);
		}
		.l-btn{
            @include vw($padding: 15 10,
            $width: 300,
            $font-size: 26,
            $border: 4 solid $brand);
            font-weight: $bold;
			color: $brand;
			opacity: 1;
			text-align: center;
			transition: all 0.4s ease;
			float: none;
			text-decoration: none;
			margin: auto;
			display: block;
			&:hover, &:active, &:focus{
				color: #fff;
				text-decoration:none;
				background: $dark;
			}
		}
		.l-supplements.r-size{
			@include vw($max-width: 800);
			@include vw($margin: 50 auto);
		}
		.b-modal_contact{
			@include vw($margin-top: 30);
			span{
				color: $dark;
				font-weight: $bold;
				display: block;
				@include vw($font-size: 26);
			}
			a{
				@include vw($font-size: 24);
			}
			.b-left, .b-.right{
				width: 50%;
				float: left;
			}

		}
	}

}