$brand: #9D758D;  
$dark: #926B89; 
$text: #000;
/*
==============================
Fonts
==============================
*/

$ff:   'Poppins', sans-serif; 
$light: 300;
$reg: 400;
$med: 500;
$semi-bold: 600;
$bold: 700;
$ext-bold: 800;


